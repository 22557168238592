import { createSlice } from '@reduxjs/toolkit';
import { CLEAN_ALL_STATES } from '../global-actions';
import { GlobalParameterState } from './global-parameter';
import { GET_GLOBAL_PARAMETERS, UPDATE_LABOR_COST } from './global-parameter.thunk';

const initialState: GlobalParameterState = {
  parameters: undefined,
  isLoading: false,
};

export const globalParameterSlice = createSlice({
  name: 'globalParameterSlice',
  initialState,
  reducers: {
    cleanGlobalParameters(state) {
      return {
        ...state,
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GET_GLOBAL_PARAMETERS.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(GET_GLOBAL_PARAMETERS.fulfilled, (state, action) => ({
      ...state, parameters: action.payload[0], isLoading: false,
    }));
    builder.addCase(GET_GLOBAL_PARAMETERS.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(UPDATE_LABOR_COST.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UPDATE_LABOR_COST.fulfilled, (state, action) => ({
      ...state, parameters: action.payload, isLoading: false,
    }));
    builder.addCase(UPDATE_LABOR_COST.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(CLEAN_ALL_STATES, (state) => ({
      ...state,
      ...initialState,
    }));
  },
});

export const GlobalParameterActions = {
  ...globalParameterSlice.actions,
};
