import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import {
  CreateProductionOrderDto,
  FlashProductionOrderOperationStartDto,
  FlashProductionOrderOperationStopDto,
  UpdateOperationInProductionOrderDto,
  UpdateProductionOrderDto,
  UploadProductionOrderFileDto, 
} from './production-order';
import { ErrorApiModel } from '../../config/error-api.model';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const FETCH_ALL_PRODUCTION_ORDERS = createAsyncThunk(
  'production-order/FETCH_ALL_PRODUCTION_ORDERS',
  async (payload: {startDate?: Dayjs, endDate?: Dayjs}, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/production-orders/get-all`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ALL_PRODUCTION_ORDERS_CODES = createAsyncThunk(
  'production-order/FETCH_ALL_PRODUCTION_ORDERS_CODES',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(`${url}/production-orders/get-all-codes`);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS = createAsyncThunk(
  'production-order/FETCH_ALL_OPERATIONS_IN_PRODUCTION_ORDERS',
  async (payload: {startDate?: Dayjs, endDate?: Dayjs, code?:string}, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/production-orders/operations/get-all`, payload);
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const GET_ONE_PRODUCTION_ORDER_BY_ID = createAsyncThunk(
  'production-order/GET_ONE_PRODUCTION_ORDER_BY_ID',
  async (id: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/production-orders/${id}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CLOSE_PRODUCTION_ORDER_BY_ID = createAsyncThunk(
  'production-order/CLOSE_PRODUCTION_ORDER_BY_ID',
  async (id: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(
        `${url}/production-orders/close-of/${id}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const GET_ONE_PRODUCTION_ORDER_BY_CODE = createAsyncThunk(
  'production-order/GET_ONE_PRODUCTION_ORDER_BY_CODE',
  async (code: string, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/production-orders/code/${code}`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPLOAD_PRODUCTION_ORDER_FILE = createAsyncThunk(
  'production-order/UPLOAD_PRODUCTION_ORDER_FILE',
  async (payload: UploadProductionOrderFileDto, thunkApi) => {
    try {
      const { file, uploadRuleCode, scriptCode } = payload;
      const formData = new FormData();
      formData.append('file', file);
      if (scriptCode) formData.append('scriptCode', scriptCode);
      if (uploadRuleCode) formData.append('uploadRuleCode', uploadRuleCode);

      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/production-orders/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Fichier envoyé avec succès',
          color: 'success',
          timeout: 6000,
        }),
      );

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Problème lors de l\'envoie du fichier',
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const CREATE_PRODUCTION_ORDER = createAsyncThunk(
  'production-order/CREATE_PRODUCTION_ORDER',
  async (payload: CreateProductionOrderDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.post(`${url}/production-orders`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Informations enregistrées',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_PRODUCTION_ORDER = createAsyncThunk(
  'production-order/UPDATE_PRODUCTION_ORDER',
  async (payload: UpdateProductionOrderDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/production-orders`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Informations enregistrées',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FLASH_PRODUCTION_ORDER_OPERATION_START = createAsyncThunk(
  'production-order/FLASH_PRODUCTION_ORDER_OPERATION_START',
  async (payload: FlashProductionOrderOperationStartDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/production-orders/operation/flash-barcode/start`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Informations enregistrées',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const FLASH_PRODUCTION_ORDER_OPERATION_STOP = createAsyncThunk(
  'production-order/FLASH_PRODUCTION_ORDER_OPERATION_STOP',
  async (payload: FlashProductionOrderOperationStopDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/production-orders/operation/flash-barcode/stop`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Informations enregistrées',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_OPERATION_IN_PRODUCTION_ORDER = createAsyncThunk(
  'production-order/UPDATE_OPERATION_IN_PRODUCTION_ORDER',
  async (payload: UpdateOperationInProductionOrderDto, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.patch(`${url}/production-orders/operation`, payload);
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: 'Informations enregistrées',
          color: 'success',
          timeout: 3000,
        }),
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);
