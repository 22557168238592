import CreditCardIcon from '@mui/icons-material/CreditCard';
import { DrawerMenu } from '../DashboardCardLists/menu-list';
import { PERMISSIONS } from '../roles-permissions-list';

export const DRAWER_GLOBAL_PARAMETERS_LIST: DrawerMenu[] = [
  {
    id: 'global-parameters',
    path: '/global-parameters',
    label: 'Coût de revient',
    subLabel: 'par défaut',
    icon: CreditCardIcon,
    color: '',
    permissions: [PERMISSIONS.GLOBAL_PARAMETERS],
  },
];
