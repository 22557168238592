import { createAsyncThunk } from '@reduxjs/toolkit';
import { UiSlice } from '../ui/ui.slice';
import axiosApiInstance from '../../config/axios';
import { ErrorApiModel } from '../../config/error-api.model';

const { displayAlert, displayLoader } = UiSlice.actions;

const url: string = process.env.REACT_APP_API_BASE_URI || '/api';

export const GET_GLOBAL_PARAMETERS = createAsyncThunk(
  'global-parameter/GET_GLOBAL_PARAMETERS',
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(displayLoader(true));
      const result = await axiosApiInstance.get(
        `${url}/global-parameters`,
      );
      thunkApi.dispatch(displayLoader(false));

      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 3000,
        }),
      );
      throw error;
    }
  },
);

export const UPDATE_LABOR_COST = createAsyncThunk(
  'global-parameter/UPDATE_LABOR_COST',
  async (laborCost: string, thunkApi) => {
    try {
      const result = await axiosApiInstance.patch(
        `${url}/global-parameters/labor-cost`,
        {
          laborCost,
        },
      );
      return result.data;
    } catch (error: unknown) {
      thunkApi.dispatch(displayLoader(false));
      thunkApi.dispatch(
        displayAlert({
          message: (error as ErrorApiModel).message,
          color: 'error',
          timeout: 5000,
        }),
      );
      throw error;
    }
  },
);
