export interface Roles {
  [key: string]: {
    description: string;
    permissionList: string[];
  };
}

export const PERMISSIONS = {
  ARTICLE_LIST: 'ARTICLE_LIST',
  ARTICLE_CONF: 'ARTICLE_CONF',
  ARTICLE_UPLOAD: 'ARTICLE_UPLOAD',
  NOMENCLATURE_FORMATTED_LIST: 'NOMENCLATURE_FORMATTED_LIST',
  NOMENCLATURE_COEF_LIST: 'NOMENCLATURE_COEF_LIST',
  NOMENCLATURE_CONF: 'NOMENCLATURE_CONF',
  NOMENCLATURE_UPLOAD: 'NOMENCLATURE_UPLOAD',
  FORECAST_LIST: 'FORECAST_LIST',
  FORECAST_CONF: 'FORECAST_CONF',
  FORECAST_UPLOAD: 'FORECAST_UPLOAD',
  STOCK_LIST: 'STOCK_LIST',
  STOCK_CONF: 'STOCK_CONF',
  STOCK_UPLOAD: 'STOCK_UPLOAD',
  PRODUCTION_ORDER_LIST: 'PRODUCTION_ORDER_LIST',
  PRODUCTION_ORDER_UPLOAD: 'PRODUCTION_ORDER_UPLOAD',
  OPERATOR_TIME: 'OPERATOR_TIME',
  OPERATOR_FLASH_BARCODE: 'OPERATOR_FLASH_BARCODE',
  OPERATOR_REAL_TIME: 'OPERATOR_REAL_TIME',
  SECTION_TIME: 'SECTION_TIME',
  TIME_INDICATOR: 'TIME_INDICATOR',
  ORGANISATION_LIST: 'ORGANISATION_LIST',
  USER_LIST: 'USER_LIST',
  OPERATOR_LIST: 'OPERATOR_LIST',
  OPERATION: 'OPERATION',
  ROUTING: 'ROUTING',
  UPLOAD_RULE_LIST: 'UPLOAD_RULE_LIST',
  SCRIPT_SPECIFIC: 'SCRIPT_SPECIFIC',
  MAPPING_TABLE_LIST: 'MAPPING_TABLE_LIST',
  VALUE_LIST_LIST: 'VALUE_LIST_LIST',
  PRODUCTION_LINE: 'PRODUCTION_LINE',
  UNIT: 'UNIT',
  SUPPLIER_LIST: 'SUPPLIER_LIST',
  GLOBAL_PARAMETERS: 'GLOBAL_PARAMETERS',
};

export const PERMISSION_TRANSLATIONS: { [key: string]: string } = {
  ARTICLE_LIST: 'Liste Articles',
  ARTICLE_CONF: 'Configuration Table Articles',
  ARTICLE_UPLOAD: 'Chargement Articles',
  NOMENCLATURE_FORMATTED_LIST: 'Liste Nomenclatures',
  NOMENCLATURE_COEF_LIST: 'Liste Coefficients Nomenclatures',
  NOMENCLATURE_CONF: 'Configuration Nomenclatures',
  NOMENCLATURE_UPLOAD: 'Chargement Nomenclatures',
  FORECAST_LIST: 'Liste Prévisions',
  FORECAST_CONF: 'Configuration Prévisions',
  FORECAST_UPLOAD: 'Chargement Prévisions',
  STOCK_LIST: 'Liste Stocks',
  STOCK_CONF: 'Configuration Stocks',
  STOCK_UPLOAD: 'Chargement Stocks',
  PRODUCTION_ORDER_LIST: 'Liste Ordres de Fabrication',
  PRODUCTION_ORDER_UPLOAD: 'Chargement Ordres de Fabrication',
  OPERATOR_TIME: 'Temps Opérateur',
  OPERATOR_REAL_TIME: 'Temps Réel Opérateur',
  OPERATOR_FLASH_BARCODE: 'Flasher Code Barre',
  SECTION_TIME: 'Liste Temps Section',
  TIME_INDICATOR: 'Indicateur Temps',
  ORGANISATION_LIST: 'Liste Organisations',
  USER_LIST: 'Liste Utilisateurs',
  OPERATOR_LIST: 'Liste Opérateurs',
  OPERATION: 'Management Opérations',
  ROUTING: 'Management Gammes',
  UPLOAD_RULE_LIST: 'Liste Règles d\'Import',
  SCRIPT_SPECIFIC: 'Liste Scripts Spécifiques',
  MAPPING_TABLE_LIST: 'Liste Tables de Correspondance',
  VALUE_LIST_LIST: 'Liste Listes de Valeurs',
  PRODUCTION_LINE: 'Liste Lignes de Production',
  UNIT: 'Liste des unités',
  SUPPLIER_LIST: 'Liste des fournisseurs',
  GLOBAL_PARAMETERS: 'Paramètres globaux',
};

export const ROLES: Roles = {
  ARTICLE: {
    description: 'Gestion des articles',
    permissionList: [PERMISSIONS.ARTICLE_LIST, PERMISSIONS.ARTICLE_CONF, PERMISSIONS.ARTICLE_UPLOAD],
  },
  NOMENCLATURE: {
    description: 'Gestion des nomenclatures',
    permissionList: [PERMISSIONS.NOMENCLATURE_FORMATTED_LIST, 
      PERMISSIONS.NOMENCLATURE_COEF_LIST, PERMISSIONS.NOMENCLATURE_CONF, PERMISSIONS.NOMENCLATURE_UPLOAD],
  },
  FORECAST: {
    description: 'Gestion des prévisions',
    permissionList: [PERMISSIONS.FORECAST_CONF, PERMISSIONS.FORECAST_LIST, PERMISSIONS.FORECAST_UPLOAD],
  },
  STOCK: {
    description: 'Gestion des stocks',
    permissionList: [PERMISSIONS.STOCK_CONF, PERMISSIONS.STOCK_LIST, PERMISSIONS.STOCK_UPLOAD],
  },
  PRODUCTION_ORDER: {
    description: 'Gestion des ordres de fabrication',
    permissionList: [PERMISSIONS.PRODUCTION_ORDER_LIST, PERMISSIONS.PRODUCTION_ORDER_UPLOAD],
  },
  TIME_TRACKER: {
    description: 'Gestion des temps',
    permissionList: [PERMISSIONS.TIME_INDICATOR, PERMISSIONS.SECTION_TIME, PERMISSIONS.OPERATOR_TIME, 
      PERMISSIONS.OPERATOR_REAL_TIME, PERMISSIONS.OPERATOR_FLASH_BARCODE],
  },
  ORGANISATION_USER: {
    description: 'Gestion des organisations et utilisateurs',
    permissionList: [PERMISSIONS.ORGANISATION_LIST, PERMISSIONS.USER_LIST, PERMISSIONS.OPERATOR_LIST],
  },
  PRODUCTION_REFERENTIAL: {
    description: 'Gestion du référentiel GP',
    permissionList: [PERMISSIONS.ROUTING, PERMISSIONS.OPERATION, PERMISSIONS.PRODUCTION_LINE, PERMISSIONS.UNIT],
  },
  UPLOAD_RULE: {
    description: 'Gestion des règles d\'import',
    permissionList: [PERMISSIONS.UPLOAD_RULE_LIST, PERMISSIONS.SCRIPT_SPECIFIC],
  },
  MAPPING_TABLE: {
    description: 'Gestion des tables de correspondance',
    permissionList: [PERMISSIONS.MAPPING_TABLE_LIST],
  },
  VALUE_LIST: {
    description: 'Gestion des listes de valeurs',
    permissionList: [PERMISSIONS.VALUE_LIST_LIST],
  },
  SUPPLIER_LIST: {
    description: 'Gestion des fournisseurs',
    permissionList: [PERMISSIONS.SUPPLIER_LIST],
  },
  GLOBAL_PARAMETERS: {
    description: 'Gestion des paramètres généraux',
    permissionList: [PERMISSIONS.GLOBAL_PARAMETERS],
  },
  OPERATOR: {
    description: 'Rôle opérateur',
    permissionList: ['OPERATOR_FLASH_BARCODE'],
  },
};
