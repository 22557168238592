/* eslint-disable import/no-cycle */
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { UiSlice } from './ui/ui.slice';
import { userSlice } from './user/user.slice';
import { columnSlice } from './column/column.slice';
import { articleSlice } from './article/article.slice';
import { uploadRuleSlice } from './upload-rule/upload-rule.slice';
import { editorSlice } from './editor/editor.slice';
import { correspondenceTableSlice } from './correspondence-table/correspondence-table.slice';
import { nomencSlice } from './nomenc/nomenc.slice';
import { forecastSlice } from './forecast/forecast.slice';
import { stockSlice } from './stock/stock.slice';
import { operationSlice } from './operation/operation.slice';
import { routingSlice } from './routing/routing.slice';
import { productionOrderSlice } from './production-order/production-order.slice';
import { labelSlice } from './label/label.slice';
import { organisationSlice } from './organisation/organisation.slice';
import { sectionSlice } from './section/section.slice';
import { actionSlice } from './action/action.slice';
import { taskSlice } from './task/task.slice';
import { operatorSlice } from './operator/operator.slice';
import { valueListSlice } from './value-list/value-list.slice';
import { productionLineSlice } from './production-line/production-line.slice';
import { unitSlice } from './unit/unit.slice';
import { supplierSlice } from './supplier/supplier.slice';
import { collectionSlice } from './collection/collection.slice';
import { timeTrackerSlice } from './time-tracker/time-tracker.slice';
import { globalParameterSlice } from './global-parameter/global-parameter.slice';

const combinedReducer = combineReducers({
  ui: UiSlice.reducer,
  organisation: organisationSlice.reducer,
  user: userSlice.reducer,
  column: columnSlice.reducer,
  article: articleSlice.reducer,
  uploadRule: uploadRuleSlice.reducer,
  editor: editorSlice.reducer,
  correspondenceTable: correspondenceTableSlice.reducer,
  nomenc: nomencSlice.reducer,
  forecast: forecastSlice.reducer,
  stock: stockSlice.reducer,
  operation: operationSlice.reducer,
  productionLine: productionLineSlice.reducer,
  unit: unitSlice.reducer,
  routing: routingSlice.reducer,
  productionOrder: productionOrderSlice.reducer,
  label: labelSlice.reducer,
  section: sectionSlice.reducer,
  action: actionSlice.reducer,
  task: taskSlice.reducer,
  operator: operatorSlice.reducer,
  valueList: valueListSlice.reducer,
  suppliers: supplierSlice.reducer,
  collections: collectionSlice.reducer,
  timeTracker: timeTrackerSlice.reducer,
  globalParameter: globalParameterSlice.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'user/cleanUser') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware),
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
